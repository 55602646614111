var editorConfig = {
     UEDITOR_HOME_URL: '/UEditor/', // 访问 UEditor 静态资源的根路径
     autoHeightEnabled: false,
     initialFrameHeight: 240,
     initialFrameWidth: '100%',
     elementPathEnabled: false,
     enableAutoSave: false,
     pasteplain: true,
     autoSyncData: false,
     autoFloatEnabled: false,
     lang: "zh-cn",
     toolbars: [[
          'undo', 'redo', '|', 'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
          'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
          'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
          'directionalityltr', 'directionalityrtl', 'indent', '|',
          'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
          'link', 'unlink', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
          'emotion', 'background', '|',
          'horizontal', 'date', 'time', 'spechars', '|',
          'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
          'preview', 'searchreplace', 'drafts'
     ]]
};

module.exports = editorConfig